@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Manrope', sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Orbitron', sans-serif;
}

.overallImage {
  background-image: url('./assests/banner3.jpg');
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #fff;
}
